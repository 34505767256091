import Velocity from 'velocity-animate';
import EasyPieChart from 'easy-pie-chart';
import Pristine from 'pristinejs/dist/pristine';

const cvContact = document.querySelector('.cv-contact');
const name = document.getElementById('name');
const email = document.getElementById('email');
const message = document.getElementById('message');
const back = document.getElementById('back-to-top');
const pies = document.querySelectorAll('.pie-chart');
let keepClosed = false;

const reCaptchaOnFocus = () => {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src =
    'https://www.google.com/recaptcha/api.js?render=6Lc6F5EUAAAAAAlXoKq7pQfeYFRGIrgLOzKRZmHm';
  head.appendChild(script);

  // remove focus to avoid js error:
  name.removeEventListener('focus', reCaptchaOnFocus);
  email.removeEventListener('focus', reCaptchaOnFocus);
  message.removeEventListener('focus', reCaptchaOnFocus);
};

const addReCaptchaListeners = () => {
  name.addEventListener('focus', reCaptchaOnFocus, false);
  email.addEventListener('focus', reCaptchaOnFocus, false);
  message.addEventListener('focus', reCaptchaOnFocus, false);
};

const cvContactIsOpen = () =>
  window.getComputedStyle(cvContact).left === '-320px';
const toggleContactSection = () => {
  if (cvContactIsOpen()) {
    keepClosed = true;
  }
  Velocity(cvContact, {
    left: cvContactIsOpen() ? 0 : '-320px',
  });
};

const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

const drawInitialPieCharts = () => {
  pies.forEach((chart) => {
    const r = chart.getAttribute('data-barsize');

    chart.style.height = r;
    chart.style.width = r;
    chart.style.lineHeight = r + 'px';

    /* eslint-disable no-new */
    new EasyPieChart(chart, {
      animate: { enabled: false },
      lineCap: 'square',
      lineWidth: chart.getAttribute('data-linewidth'),
      size: r,
      barColor: false,
      trackColor: chart.getAttribute('data-trackcolor'),
      scaleColor: false,
    });
  });
};

const chartsAnimated = [];
const animatePieCharts = () => {
  const resetPieChart = (chart) => {
    chart.querySelector('canvas').remove();
  };

  pies.forEach((chart, index) => {
    if (!isInViewport(chart)) {
      return;
    }

    if (chartsAnimated.includes(index)) {
      return;
    }
    chartsAnimated.push(index);

    resetPieChart(chart);

    /* eslint-disable no-new */
    new EasyPieChart(chart, {
      animate: { enabled: true, duration: 1000 },
      lineCap: 'square',
      lineWidth: chart.getAttribute('data-linewidth'),
      size: chart.getAttribute('data-barsize'),
      barColor: chart.getAttribute('data-barcolor'),
      trackColor: chart.getAttribute('data-trackcolor'),
      scaleColor: false,
      onStep: (from, to, percent) => {
        chart.querySelector('.pie-chart-percent span').textContent =
          Math.round(percent) + ' %';
      },
    });
  });
};

const barsAnimated = [];
const animateProgressBars = () => {
  document
    .querySelectorAll('.progress-bar .progress-bar-outer')
    .forEach((bar, index) => {
      if (!isInViewport(bar)) {
        return;
      }

      if (barsAnimated.includes(index)) {
        return;
      }
      barsAnimated.push(index);

      Velocity(
        bar,
        {
          width: bar.getAttribute('data-width') + '%',
        },
        2000,
      );
    });
};

const handleEnvelopToggle = () => {
  if (window.innerWidth > 767) {
    document
      .querySelector('.cv-contact-toggle')
      .addEventListener('click', toggleContactSection);
  }
};

const handleContactForm = () => {
  const spinner = document.querySelector('.fa-spinner');
  const showSpinner = () => {
    spinner.style.display = 'inline-block';
  };

  const hideSpinner = () => {
    spinner.style.display = 'none';
  };

  const form = document.getElementById('contact-form');
  const status = document.getElementById('formstatus');
  const pristine = new Pristine(form, {
    classTo: 'input-grp',
    errorTextTag: 'label',
    errorTextClass: 'validation-error',
    errorTextParent: 'input-grp',
    errorClass: 'validation-error',
  });

  form.addEventListener('submit', function (e) {
    e.preventDefault();

    if (!pristine.validate()) {
      return false;
    }

    showSpinner();
    window.grecaptcha
      .execute('6Lc6F5EUAAAAAAlXoKq7pQfeYFRGIrgLOzKRZmHm', {
        action: 'contact_form',
      })
      .then((token) => {
        const xhr = new XMLHttpRequest();

        let result;
        xhr.onload = () => {
          if (xhr.status !== 200) {
            status.innerHTML =
              '<div class="alert error">' +
              'There was an error sending the message!</div>';
            hideSpinner();
            return;
          }

          const msg = xhr.responseText;
          if (msg === 'OK') {
            result =
              '<div class="alert success">' +
              'Thank you, your message has been sent. I will respond ASAP!</div>';
          } else {
            result = '<div class="alert error">' + msg + '</div>';
          }

          status.innerHTML = result;
          hideSpinner();
        };

        xhr.open(form.method, form.action, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(
          JSON.stringify({
            name: name.value,
            email: email.value,
            message: message.value,
            token,
          }),
        );
      });
  });
};

const handleBackToTop = () => {
  let scrollAnimation;
  const scrollToTop = () => {
    const position =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (position) {
      window.scrollBy(0, -Math.max(10, Math.floor(position / 10)));
      scrollAnimation = setTimeout(scrollToTop, 10);
      return;
    }

    clearTimeout(scrollAnimation);
  };

  back.addEventListener('click', () => {
    scrollToTop();
    return false;
  });
};

const isThroughTheMiddle = () =>
  document.documentElement.scrollTop > window.innerHeight / 2;

const showHidebackToTop = () => {
  if (isThroughTheMiddle()) {
    back.classList.remove('gone');
    back.classList.add('visible');
    return;
  }

  back.classList.remove('visible');
  back.classList.add('gone');
};

const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(
      new URL('service-worker.js', import.meta.url),
      {
        type: 'module',
      },
    );
  }
};

const showContactSection = () => {
  if (cvContactIsOpen() && !keepClosed && isThroughTheMiddle()) {
    toggleContactSection();
  }
};

const registerViewpointListeners = () => {
  const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
      const args = arguments;
      const callNow = immediate && !timeout;

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        if (!immediate) {
          func.apply(this, args);
        }
      }, wait);
      if (callNow) {
        func.apply(this, args);
      }
    };
  };

  const onViewpointChange = () =>
    debounce(() => {
      animatePieCharts();
      animateProgressBars();
      showHidebackToTop();
      showContactSection();
    }, 50);

  document.body.onscroll = onViewpointChange();
  document.body.onresize = onViewpointChange();
};

addReCaptchaListeners();
drawInitialPieCharts();
handleContactForm();
handleEnvelopToggle();
handleBackToTop();
registerServiceWorker();
registerViewpointListeners();
